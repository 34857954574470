import { useNuxtApp } from '#app'
import { useAPI, useCacheWrapper, useSharedPromise } from '#imports'
import { productDataForCarousel } from '@/utils/product-carousels'
import { useConfig } from '@/stores/config'

import type { ProductCollectionParameters } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_pb'

export function useProductCollections () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const { isMscRegion } = useConfig()
  const { getCollectionsForPage } = useCollections()

  async function getHomeCollections () {
    return useCacheWrapper('product-collections-home', async () => {
      const collectionsForPage = getCollectionsForPage('home', isMscRegion.value)?.map((el) => {
        return {
          code: el,
          limit: 12
        }
      })

      const data = await nuxtApp.runWithContext(() => getProductCollections(collectionsForPage, 'home'))

      return (data || []).map((el) => {
        return {
          ...el,
          productsList: productDataForCarousel(el.productsList)
        }
      })
    })
  }

  async function getProductPageCollections (code: string) {
    const { getProductCollectionsForProductPageRequest } = api.productCollection()

    return useSharedPromise(['product-collections', code], () => {
      return getProductCollectionsForProductPageRequest(code)
    })
  }

  async function getProductCollections (collection: ProductCollectionParameters.AsObject[], category: string) {
    const key = ['product-collections', category]
    const { getProductCollectionsRequest } = api.productCollection()

    return useSharedPromise(key, () => useCacheWrapper(key, () => {
      return getProductCollectionsRequest(collection)
    }))
  }

  return {
    getHomeCollections,
    getProductPageCollections,
    getProductCollections
  }
}
